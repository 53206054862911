/** Librerias necesarias */
import React from "react";
import { Router, Switch, Route, Link } from "react-router-dom";
import { View, ScrollView } from "react-native";
import "./App.css";
import history from "../src/history";
import {
  AppstoreOutlined,
  PoweroffOutlined,
  UserOutlined,
  HomeOutlined
} from "@ant-design/icons";
import { Layout, Row, Col, Button, Tabs, Popover, Tooltip, Modal, Input } from "antd";
import "@ant-design/compatible/assets/index.css";
/** Containers a utilizar */
import Logout from "./containers/Logout";
import Login from "./containers/Login";
import Home from "./containers/Home";
import AttendanceLog from "./containers/AttendanceLog";
import DevicesLog from "./containers/DevicesLog";
import DevicesGroups from "./containers/DeviceGroups";
import AccountsList from "./containers/AccountsList";
import AccountHeader from "./containers/AccountHeader";
import DevicesMonitor from "./containers/DevicesMonitor";
import ClientsMonitor from "./containers/ClientsMonitor";
import ServicesMonitor from "./containers/ServicesMonitor";
import { Form } from "@ant-design/compatible";
import config from "../src/config.json";

/** Constantes */
const { Header, Content, Footer } = Layout;
const { TabPane } = Tabs;

/** Contexto de autenticacion que almacena el token */
export const AuthContext = React.createContext();

/** Estado inicial */
const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  localStorage: false,
  urlId: null,
  showAccessModal: false,
  showMonitor: false,
  accessKey: null,
  tabKey: "1",
};

/** Reducer que se encarga de las acciones */
const reducer = (state, action) => {
  switch (action.type) {
    /** Si hay un login se guarda localmente la información */
    case "LOGIN":
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("token", JSON.stringify(action.payload.token));
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
      };
    /** Si se presenta un Logout se elimina la información guardada localmente */
    case "LOGOUT":
      localStorage.clear();
      window.location.replace("/");
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    /** Esta acción determina en que tab se encontraba el usuario */
    case "SET_KEY":
      return {
        ...state,
        tabKey: action.payload,
      };
    case "CHANGE_CONTENT":
      return {
        ...state,
        showMonitor: action.value
      };
    case "CONTROL_MODAL":
      return {
        ...state,
        showAccessModal: action.value
      };
    case "CHANGE_KEY":
      return {
        ...state,
        accessKey: action.value
      };
    default:
      return state;
  }
};

/** Función principal */
function App() {
  var urlParam = window.location.pathname.split("/")[1];
  /** Parametro de URL que contiene el Id del cliente */
  var urlId = window.location.pathname.split("/")[2];
  if (urlId !== undefined) {
    localStorage.setItem("urlId", urlId.toString());
  }
  /** Hooks a utilizar */
  const [state, dispatch] = React.useReducer(reducer, initialState);

  /** Función ejecutada una vez cargado el componente */
  React.useEffect(() => {
    /** La constante token obtiene el valor del mismo guardado localmente */
    const token = JSON.parse(localStorage.getItem("token"));
    /** Si el token existe entonces el usuario esta logeado */
    if (token) {
      /** En caso de que el parametro de URL se encuentra vacīo de deslogea al usuario */
      if (urlParam === "terminales" || urlParam === "clientes" || urlParam === "servicios") {
        dispatch({
          type: "LOGIN",
          payload: {
            token,
          }
        });
      }
      else {
        if (urlId === null || urlId === undefined || urlId === "") {
          dispatch({
            type: "LOGOUT",
          });
        } else {
          dispatch({
            type: "LOGIN",
            payload: {
              token,
            },
          });
        }
      }
      /** Establece la dirección del Tab */
      var keyParam = window.location.pathname.split("/")[1];
      if (keyParam === "terminales" || keyParam === "clientes" || keyParam === "servicios") {
        var hasAccess = localStorage.getItem("hasAccess");
        if(hasAccess === null) {
          dispatch({
            type: "CONTROL_MODAL",
            value: true
          });
        }
        changeContent(true, keyParam);
        switch (keyParam) {
          case "terminales":
            dispatch({
              type: "SET_KEY",
              payload: "5",
            });
            break;
          case "servicios":
            dispatch({
              type: "SET_KEY",
              payload: "6",
            });
            break;
          case "clientes":
              dispatch({
                type: "SET_KEY",
                payload: "7",
              });
              break;
        }
      }
      else {
        changeContent(false);
        switch (keyParam) {
          case "consulta-terminales":
            dispatch({
              type: "SET_KEY",
              payload: "1",
            });
            break;
          case "checadas":
            dispatch({
              type: "SET_KEY",
              payload: "2",
            });
            break;
          case "bitacora":
            dispatch({
              type: "SET_KEY",
              payload: "3",
            });
            break;
          case "grupos":
            dispatch({
              type: "SET_KEY",
              payload: "4",
            });
            break;
        }
      }
    }
  }, []);

  /** Maneja el cambio de estado al realizar un clic en el Tabulador */
  function handleTabClick(key) {
    switch (key) {
      case "1":
        history.push(`/consulta-terminales/` + urlId);
        break;
      case "2":
        history.push("/checadas/" + urlId);
        break;
      case "3":
        history.push("/bitacora/" + urlId);
        break;
      case "4":
        history.push("/grupos/" + urlId);
        break;
      case "5":
        history.push("/terminales");
        break;
      case "6":
        history.push("/servicios");
        break;
      case "7":
          history.push("/clientes");
          break;
      default:
        break;
    }
  }

  function changeContent(showMonitor, keyParam) {
    dispatch({
      type: "CHANGE_CONTENT",
      value: showMonitor
    });
    if(keyParam === null || keyParam === undefined) {
      history.push(`/consulta-terminales/` + JSON.parse(localStorage.getItem("urlId")));
      dispatch({
        type: "CONTROL_MODAL",
        value: false
      });
    }
    else{
      history.push("/" + keyParam);
    }
  };

  function changeAccessValue(e) {
    dispatch({
      type: "CHANGE_KEY",
      value: e.target.value
    });
  }

  function submitAccessKey(event) {
    if(event)
      event.preventDefault();
    if(state.accessKey.toString() === config.security.accessKey) {
      dispatch({
        type: "CONTROL_MODAL",
        value: false
      });
      localStorage.setItem("hasAccess", JSON.stringify(1));
    }
  }

  /** Manejo de tipo Layout de la aplicación */
  return (
    /** Contexto de autorización que realiza las acciones anteriores */
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {
        <Router basename={"/"} history={history}>
          <View style={{ flex: 1 }}>
            {!state.isAuthenticated ? (
              <div className="login_body">
                <Login />
              </div>
            ) : (
              <div>
                <Layout
                  className="layout"
                  style={{
                    width: "100%",
                    height: "100%",
                    background: "#fffff",
                  }}
                >
                  <Header
                    style={{
                      background: "#fff",
                      position: "fixed",
                      zIndex: 1,
                      width: "100%",
                    }}
                  >
                    <div>
                      <div className="gti_breadcrumb">
                        <div className="gti_breadcrumb_nav">
                          <Row type="flex" justify="start">
                            <Col span={20}>
                              {!state.showMonitor ?
                                <Tabs
                                  style={{ paddingTop: 8 }}
                                  defaultActiveKey={state.tabKey}
                                  onChange={handleTabClick}
                                >
                                  <TabPane key="1" tab="Terminales"></TabPane>
                                  <TabPane key="2" tab="Checadas"></TabPane>
                                  <TabPane key="3" tab="Bitácora"></TabPane>
                                  <TabPane key="4" tab="Grupos"></TabPane>
                                </Tabs>
                                :
                                <Tabs
                                  style={{ paddingTop: 8 }}
                                  defaultActiveKey={state.tabKey}
                                  onChange={handleTabClick}
                                >
                                  <TabPane key="5" tab="Terminales"></TabPane>
                                  <TabPane key="6" tab="Servicios"></TabPane>
                                  <TabPane key="7" tab="Clientes"></TabPane>
                                </Tabs>}

                            </Col>
                            <Col span={4}>
                              <Row type="flex" justify="end">
                                {state.showMonitor ? <Col>
                                  <Tooltip placement="bottom" title={"Volver a Consulta"}>
                                    <Button
                                      onClick={() => changeContent(false)}
                                      type="link"
                                      icon={<HomeOutlined />}
                                    ></Button>
                                  </Tooltip>
                                </Col> : <div></div>}
                                <Col>
                                  <Popover
                                    placement="bottom"
                                    title="Cuentas"
                                    content={<AccountsList />}
                                    trigger="hover"
                                  >
                                    <Button
                                      type="link"
                                      icon={<AppstoreOutlined />}
                                    ></Button>
                                  </Popover>
                                </Col>
                                <Col>
                                  <Popover
                                    placement="bottom"
                                    title="Opciones de usuario"
                                    content={
                                      <div className="App">
                                        <Link type="link" to="/logout">
                                          <PoweroffOutlined />
                                          {" Cerrar sesión"}
                                        </Link>
                                      </div>
                                    }
                                    trigger="hover"
                                  >
                                    <Button

                                      type="link"
                                      icon={<UserOutlined />}
                                    ></Button>
                                  </Popover>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Header>
                  <Content
                    className="Content_background"
                    style={{
                      marginTop: 67,
                      backgroundColor: "#f5f5f7",
                      padding: "0 26px",
                    }}
                  >
                    <br />
                    <ScrollView
                      style={{
                        padding: 30,
                        margin: "24px 16px 0",
                        backgroundColor: "white",
                        overflow: "initial",
                      }}
                    >
                      <Content>
                        <div style={{ minHeight: 500 }}>
                          <Row style={{ paddingBottom: 20, textAlign: "left" }}>
                            <Col span={24}>
                              {state.showMonitor ? <div></div> : <AccountHeader accountId={urlId} />}
                            </Col>
                          </Row>
                          <Switch>
                            <Route
                              name="home"
                              exact
                              path={`/consulta-terminales/:id`}
                              render={(props) => <Home {...props} />}
                            />
                            <Route
                              name="bitacora"
                              exact
                              path={`/checadas/:id`}
                              render={(props) => <AttendanceLog {...props} />}
                            />
                            <Route
                              name="bitacora"
                              exact
                              path={`/bitacora/:id`}
                              render={(props) => <DevicesLog {...props} />}
                            />
                            <Route
                              name="grupos"
                              exact
                              path={`/grupos/:id`}
                              render={(props) => <DevicesGroups {...props} />}
                            />
                            <Route
                              name="monitorterminales"
                              exact
                              path={`/terminales`}
                              render={() => <DevicesMonitor />}
                            />
                            <Route
                              name="monitorservicios"
                              exact
                              path={`/servicios`}
                              render={() => <ServicesMonitor />}
                            />
                            <Route
                              name="monitorclientes"
                              exact
                              path={`/clientes`}
                              render={() => <ClientsMonitor />}
                            />
                            <Route
                              name="logout"
                              exact
                              path={`/logout`}
                              component={Logout}
                            />
                          </Switch>
                        </div>
                        <Modal
                          destroyOnClose={true}
                          title={"Acceso a monitoreo"}
                          centered
                          width={300}
                          bodyStyle={{ height: 200 }}
                          visible={state.showAccessModal}
                          maskClosable={false}
                          onCancel={() => changeContent(false)}
                          footer={null}
                        >
                          <Form onSubmit={submitAccessKey}>
                            <Form.Item>
                              <Form.Item label="Clave de acceso:">
                                <Input
                                  onChange={changeAccessValue}
                                  maxLength="10"
                                  name="access_key"
                                  required
                                  style={{ width: 200 }}
                                  value={state.accessKey}
                                />
                              </Form.Item>
                              <Row style={{ padding: 10, textAlign: "center"}}>
                                <Col span={12}>
                                  <Button
                                    type="secondary"
                                    style={{ marginRight: 10}}
                                    onClick={() => changeContent(false)}
                                  >
                                    {"Cancelar"}
                                  </Button>
                                </Col>
                                <Col span={12}>
                                  <Button
                                    type="primary"
                                    style={{ margin: 2}}
                                    htmlType="submit"
                                  >
                                    {"Ingresar"}
                                  </Button>
                                </Col>
                              </Row>
                            </Form.Item>
                          </Form>
                        </Modal>
                      </Content>
                    </ScrollView>
                  </Content>
                  <View>
                    <Footer
                      style={{
                        textAlign: "center",
                        position: "fixed",
                        width: "100%",
                      }}
                    >
                      {" "}
                      Administrador de Terminales © {new Date().getFullYear()} -
                      Grupo Tress Internacional{" "}
                    </Footer>
                  </View>
                </Layout>
              </div>
            )}
          </View>
        </Router>
      }
    </AuthContext.Provider>
  );
}

export default App;
